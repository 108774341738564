import { Box } from "@mui/material";
import React, { useEffect } from "react";
import UniversalLoader from "../../components/global/elements/UniversalLoader";
import { userRoles } from "../../lib/constants";
import { handleAnonymousLogin, HandleLogin } from "../../services/auth";

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  },
};

const GuestUser = () => {
  useEffect(() => {
    const fetchroute = async () => {
      try {
        let navRoute = await HandleLogin();
        if (!navRoute) {
          handleAnonymousLogin({ role: userRoles.client });
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchroute();
  }, []);

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <UniversalLoader size={120} />
      </Box>
    </Box>
  );
};

export default GuestUser;
